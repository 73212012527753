body{
  background-color: rgb(30, 29, 29);
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

 @media screen and (min-width:960px) {
      
  }
  
  @media screen and (min-width:761px) {
      
  }
  
  @media screen and (max-width:760px) {
   
  }
  
  @media screen and (max-width:480px) {
   
  }
  
  @media screen and (max-width:360px) {
    
  }


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.img-thumbnail.my-2{
  height: 150px;
  width: 300px;
  color: light;
}
.container.bg-black{
  display: flex;
  color: white;
  border-radius: 10px;

}
.text.mx-3{
  font-size: 15px;;
}
.name.my-3{
  font-size: 12px;
  text-align: left;
  text-decoration: solid;
  text-align: left;
}
.d-inline-block.align-text-top{
  border-radius: 300%;
}
.btn.btn-secondary{
  background-color: rgb(30, 29, 29);
  border: none;
  border-color: rgb(30, 29, 29);
  outline: none;
}
.navbar-brand.text-light.mx-5{
  font-family: serif;
  font-size: x-large;
}
.navbar-brand.text-light.mx-1{
  font-family: serif;
  font-size: large;
  display: inline;
}
.navbar-brand.text-light.mx-0{
  font-size: medium;
  font-family: Arial;
  padding: 10px;
}
.nav-link.active.text-light{
  font-size: medium;
  font-family: Arial;
  padding: 10px;
}
.nav-link.text-light{
  font-size: medium;
  font-family: Arial;
  padding: 10px;
}
.btn.btn-secondary.dropdown-toggle.bg-dark{
  width: 100%;
}
